<template>
  <v-container fluid>
    <PageNavigation :items="items" />

    <v-row>
      <v-col cols="12">
        <base-material-card
          :title="$vuetify.lang.t('$vuetify.quertList')"
          icon="mdi-apple-finder"
        >
          <v-sheet>
            <v-toolbar-items class="d-flex align-center">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.search')"
                v-model="search"
                append-icon="mdi-magnify"
              />
              <v-spacer />
              <v-btn color="green" elevation="2" @click="handleDialog"
                ><v-icon left>mdi-account-plus</v-icon
                >{{ $vuetify.lang.t("$vuetify.add") }}</v-btn
              >
            </v-toolbar-items>
          </v-sheet>

          <tableImg
            :head="headers"
            :data="datas"
            :search="search"
            v-on:edititem="handleDialog"
            v-on:deleteitem="handleDeleteDialog"
          />
        </base-material-card>

        <QuestionCRUD
          :dialog.sync="dialog"
        />

        <DialogDelete
          :dialogDelete.sync="dialogDelete"
          :deleteObj="deleteObj"
          @handleDelete="handleDeleteConfirm"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { QuertList } from '@/api/account'

export default {
  name: "questionList",
  components: {
    tableImg: () => import("@/components/tableImg"),
    QuestionCRUD: () => import("@/components/customerService/questionCRUD"),
    DialogDelete: () => import("@/components/deleteDialog"),
    PageNavigation: () => import("@/components/pageNavigation"),
  },
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      deleteObj: {},
      items: [
        { text: this.$vuetify.lang.t("$vuetify.home") },
        { text: this.$vuetify.lang.t("$vuetify.user") },
        { text: this.$vuetify.lang.t("$vuetify.quertList") },
      ],
      statuses: ["Pending", "To be received", "off the stocks", "All"],
      warehouses: ["All", "A9"],
      datas: [],
      search: "",
      headers: [
        {
          text: this.$vuetify.lang.t("$vuetify.itemNo"),
          //value: "itemNo"
        },
        {
          text: this.$vuetify.lang.t("$vuetify.num"),
          //value: "po",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.title"),
          //value: "products.nick_name",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.level"),
        },
        {
          text: this.$vuetify.lang.t("$vuetify.classify"),
        },
        {
          text: this.$vuetify.lang.t("$vuetify.company"),
        },
        {
          text: this.$vuetify.lang.t("$vuetify.lastQuestion"),
        },
        {
          text: this.$vuetify.lang.t("$vuetify.lastReply"),
        },
        {
          text: this.$vuetify.lang.t("$vuetify.opt"),
          value: "action",
        }
      ],
      obj: {

      },
    };
  },
  methods: {
    getData() {
      QuertList()
        .then((data) => {
          this.datas = [...data.items];
        })
        .catch((err) => console.log(err));
    },
    handleDialog(){
      this.dialog = true;
    },
    handleDeleteDialog(obj) {
      this.dialogDelete = true;
      this.deleteObj = obj;
    },
    handleDeleteConfirm() {
      this.dialogDelete = false;
      console.log(this.deleteObj);
    },
    handleDialog(obj) {
      this.dialog = true;
      this.obj = obj;
    },
  },
  mounted() {
    this.getData();
  },
};
</script>